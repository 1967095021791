export const outreachStatusConstants = [
    { name: "Pending", value: "pending", variant: "primary" },
    { name: "Approved", value: "approved", variant: "success" },
    { name: "Soft Reject", value: "soft rejected", variant: "warning" },
    { name: "Rejected", value: "rejected", variant: "danger" },
];

export const approvedStatusConstants = 'approved'
export const pendingStatusConstants = 'pending'
export const rejectedStatusConstants = 'rejected'
export const softRejectedStatusConstants = 'soft rejected'
