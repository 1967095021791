var render = function () {
  var _vm$unpaidBackLink$, _vm$unpaidBackLink$$c, _vm$unpaidBackLink$$c2, _vm$unpaidBackLink$4, _vm$unpaidBackLink$4$, _vm$unpaidBackLink$4$2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_vm.$permissionAbility(_vm.OUTREACH_INVOICE_CREATE, _vm.permissions) ? _c('b-card-actions', {
    attrs: {
      "title": "Generate Invoice",
      "action-collapse": ""
    }
  }, [_c('div', [_c('div', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "seller",
      "options": _vm.filteredSellerOptions,
      "reduce": function reduce(option) {
        return option.id;
      },
      "label": "name",
      "placeholder": "Select Seller"
    },
    on: {
      "input": _vm.loadItems
    },
    model: {
      value: _vm.generateSellerId,
      callback: function callback($$v) {
        _vm.generateSellerId = $$v;
      },
      expression: "generateSellerId"
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0 ml-1",
    attrs: {
      "variant": "primary",
      "type": "submit",
      "disabled": !_vm.generateSellerId
    },
    on: {
      "click": function click($event) {
        return _vm.onShowGenerate();
      }
    }
  }, [_vm._v(" Generate ")])], 1)], 1)], 1)])]) : _vm._e(), _vm.$permissionAbility(_vm.OUTREACH_INVOICE_SHOW, _vm.permissions) ? _c('b-card-actions', {
    attrs: {
      "title": "Filters",
      "action-collapse": ""
    }
  }, [_c('div', [_c('div', [_c('b-row', [_c('b-col', {
    staticClass: "mb-2 mb-md-0",
    attrs: {
      "md": "3",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('b-form-input', {
    staticClass: "d-inline-block mr-sm-1",
    attrs: {
      "placeholder": "Website",
      "type": "text"
    },
    on: {
      "keyup": _vm.onSearch
    },
    model: {
      value: _vm.searchTerm,
      callback: function callback($$v) {
        _vm.searchTerm = $$v;
      },
      expression: "searchTerm"
    }
  })], 1), _c('b-col', {
    staticClass: "mb-2 mb-md-0",
    attrs: {
      "md": "6",
      "lg": "2",
      "xs": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "seller",
      "options": _vm.filteredSellerOptions,
      "reduce": function reduce(option) {
        return option.id;
      },
      "label": "name",
      "placeholder": "Select Seller"
    },
    on: {
      "input": _vm.loadItems
    },
    model: {
      value: _vm.filterSelectSellersId,
      callback: function callback($$v) {
        _vm.filterSelectSellersId = $$v;
      },
      expression: "filterSelectSellersId"
    }
  })], 1), _c('b-col', {
    staticClass: "mb-2 mb-md-0",
    attrs: {
      "md": "6",
      "lg": "2",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mb-1 custom-font",
    attrs: {
      "options": _vm.outreachInvoiceStatusConstants,
      "reduce": function reduce(item) {
        return item.value;
      },
      "label": "name",
      "placeholder": "Select Status"
    },
    on: {
      "input": _vm.loadItems
    },
    model: {
      value: _vm.filterSelectStatusId,
      callback: function callback($$v) {
        _vm.filterSelectStatusId = $$v;
      },
      expression: "filterSelectStatusId"
    }
  })], 1), _c('b-col', {
    staticClass: "mb-2 mb-md-0",
    attrs: {
      "md": "6",
      "lg": "2",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mb-1 custom-font",
    attrs: {
      "options": _vm.filteredPaymentOptions,
      "reduce": function reduce(item) {
        return item.id;
      },
      "label": "name",
      "placeholder": "Select Payment Method"
    },
    on: {
      "input": _vm.loadItems
    },
    model: {
      value: _vm.filterPaymentMethod,
      callback: function callback($$v) {
        _vm.filterPaymentMethod = $$v;
      },
      expression: "filterPaymentMethod"
    }
  })], 1), _c('b-col', {
    staticClass: "mb-2 mb-md-0",
    attrs: {
      "md": "6",
      "lg": "2",
      "xs": "12"
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "placeholder": "Select Generated Date Range",
      "config": {
        mode: 'range',
        defaultDate: [_vm.filterGeneratedStartDate, _vm.filterGeneratedEndDate]
      }
    },
    on: {
      "input": _vm.loadItems
    },
    model: {
      value: _vm.filterGeneratedDate,
      callback: function callback($$v) {
        _vm.filterGeneratedDate = $$v;
      },
      expression: "filterGeneratedDate"
    }
  })], 1)], 1)], 1)])]) : _vm._e(), _c('b-card', [_c('div', [_vm.$permissionAbility(_vm.OUTREACH_INVOICE_SHOW, _vm.permissions) ? _c('vue-good-table', {
    attrs: {
      "styleClass": "vgt-table table-custom-style striped",
      "line-numbers": false,
      "mode": "remote",
      "totalRows": _vm.totalRecords,
      "isLoading": _vm.isLoading,
      "rows": _vm.rows,
      "columns": _vm.columns,
      "sort-options": {
        enabled: false,
        multipleColumns: true,
        initialSortBy: [{
          field: 'created_at',
          type: 'desc'
        }]
      },
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      }
    },
    on: {
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange,
      "update:isLoading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      },
      "update:is-loading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$column, _props$row, _props$row2, _props$column2, _props$row3, _props$row3$site_list, _props$row3$site_list2, _props$column3, _props$row4, _props$row5, _props$row5$seller, _props$row5$seller$da, _props$column4, _props$row6, _props$row7, _props$row8, _props$row9, _props$column5, _props$row10, _props$row11, _props$row13, _props$row14, _props$row15, _props$row16, _props$row17, _props$row17$backlink, _props$row17$backlink2, _props$row17$backlink3, _props$row17$backlink4, _props$row18, _props$row19, _props$row20;
        return [(props === null || props === void 0 ? void 0 : (_props$column = props.column) === null || _props$column === void 0 ? void 0 : _props$column.field) === 'format_invoice_number' ? [props !== null && props !== void 0 && (_props$row = props.row) !== null && _props$row !== void 0 && _props$row.invoice_number ? _c('div', [_c('b-badge', {
          attrs: {
            "variant": "light-primary"
          }
        }, [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row2 = props.row) === null || _props$row2 === void 0 ? void 0 : _props$row2.invoice_number) + " ")])], 1) : _c('div', [_c('b-badge', {
          attrs: {
            "variant": "light-warning"
          }
        }, [_vm._v("NA")])], 1)] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column2 = props.column) === null || _props$column2 === void 0 ? void 0 : _props$column2.field) === 'format_website' ? [_vm._v(" " + _vm._s(_vm.formatWebsite(props === null || props === void 0 ? void 0 : (_props$row3 = props.row) === null || _props$row3 === void 0 ? void 0 : (_props$row3$site_list = _props$row3.site_list) === null || _props$row3$site_list === void 0 ? void 0 : (_props$row3$site_list2 = _props$row3$site_list.data) === null || _props$row3$site_list2 === void 0 ? void 0 : _props$row3$site_list2.website)) + " ")] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column3 = props.column) === null || _props$column3 === void 0 ? void 0 : _props$column3.field) === 'seller_format' ? [props !== null && props !== void 0 && (_props$row4 = props.row) !== null && _props$row4 !== void 0 && _props$row4.seller ? _c('div', [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row5 = props.row) === null || _props$row5 === void 0 ? void 0 : (_props$row5$seller = _props$row5.seller) === null || _props$row5$seller === void 0 ? void 0 : (_props$row5$seller$da = _props$row5$seller.data) === null || _props$row5$seller$da === void 0 ? void 0 : _props$row5$seller$da.name) + " ")]) : _c('div', [_c('b-badge', {
          attrs: {
            "variant": "light-warning"
          }
        }, [_vm._v("NA")])], 1)] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column4 = props.column) === null || _props$column4 === void 0 ? void 0 : _props$column4.field) === 'format_status' ? [!(props !== null && props !== void 0 && (_props$row6 = props.row) !== null && _props$row6 !== void 0 && _props$row6.review) ? [_c('b-badge', {
          attrs: {
            "variant": "light-secondary"
          }
        }, [_vm._v(" Pending ")])] : [(props === null || props === void 0 ? void 0 : (_props$row7 = props.row) === null || _props$row7 === void 0 ? void 0 : _props$row7.status) === _vm.paymentPaidConstants ? _c('div', [_c('b-badge', {
          attrs: {
            "variant": "light-success"
          }
        }, [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row8 = props.row) === null || _props$row8 === void 0 ? void 0 : _props$row8.status_text) + " ")])], 1) : _c('div', [_c('b-badge', {
          attrs: {
            "variant": "light-danger"
          }
        }, [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row9 = props.row) === null || _props$row9 === void 0 ? void 0 : _props$row9.status_text) + " ")])], 1)]] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column5 = props.column) === null || _props$column5 === void 0 ? void 0 : _props$column5.field) === 'format_payment_method' ? [_c('b-badge', {
          attrs: {
            "variant": "success"
          }
        }, [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row10 = props.row) === null || _props$row10 === void 0 ? void 0 : _props$row10.payment_method_text) + " ")])] : _vm._e(), props.column.field === 'action' ? _c('span', [(_props$row11 = props.row) !== null && _props$row11 !== void 0 && _props$row11.review ? [_c('span', {
          on: {
            "click": function click($event) {
              var _props$row12;
              return _vm.onShowDetails((_props$row12 = props.row) === null || _props$row12 === void 0 ? void 0 : _props$row12.id);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "EyeIcon",
            "title": "View",
            "size": "16"
          }
        })], 1)] : _vm._e(), _vm.$permissionAbility(_vm.OUTREACH_INVOICE_EDIT, _vm.permissions) && ((_props$row13 = props.row) === null || _props$row13 === void 0 ? void 0 : _props$row13.status) !== _vm.paymentPaidConstants ? [_c('span', {
          on: {
            "click": function click($event) {
              return _vm.onShow(props.row);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "Edit2Icon",
            "title": "Edit",
            "size": "16"
          }
        })], 1)] : _vm._e(), (_vm.$permissionAbility(_vm.OUTREACH_INVOICE_REVIEW, _vm.permissions) || _vm.$permissionAbility(_vm.OUTREACH_INVOICE_PROXY_REVIEW, _vm.permissions)) && ((_props$row14 = props.row) === null || _props$row14 === void 0 ? void 0 : _props$row14.status) !== _vm.paymentPaidConstants && !((_props$row15 = props.row) !== null && _props$row15 !== void 0 && _props$row15.review) ? [_c('span', {
          on: {
            "click": function click($event) {
              return _vm.onReview(props.row);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "CheckIcon",
            "title": "Review",
            "size": "16"
          }
        })], 1)] : _vm._e(), _vm.$permissionAbility(_vm.OUTREACH_INVOICE_DELETE, _vm.permissions) && ((_props$row16 = props.row) === null || _props$row16 === void 0 ? void 0 : _props$row16.status) !== _vm.paymentPaidConstants ? [_c('span', {
          on: {
            "click": function click($event) {
              return _vm.onDelete(props.row);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "TrashIcon",
            "title": "Delete",
            "size": "16"
          }
        })], 1)] : _vm._e()], 2) : _vm._e(), props.column.field === 'grand_total' ? _c('span', [_c('b-badge', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v(" " + _vm._s((props === null || props === void 0 ? void 0 : (_props$row17 = props.row) === null || _props$row17 === void 0 ? void 0 : (_props$row17$backlink = _props$row17.backlinks) === null || _props$row17$backlink === void 0 ? void 0 : (_props$row17$backlink2 = _props$row17$backlink.data[0]) === null || _props$row17$backlink2 === void 0 ? void 0 : (_props$row17$backlink3 = _props$row17$backlink2.currency) === null || _props$row17$backlink3 === void 0 ? void 0 : (_props$row17$backlink4 = _props$row17$backlink3.data) === null || _props$row17$backlink4 === void 0 ? void 0 : _props$row17$backlink4.currency) || '') + " " + _vm._s((props === null || props === void 0 ? void 0 : (_props$row18 = props.row) === null || _props$row18 === void 0 ? void 0 : _props$row18.total_amount) + (props === null || props === void 0 ? void 0 : (_props$row19 = props.row) === null || _props$row19 === void 0 ? void 0 : _props$row19.fee_amount) - (props === null || props === void 0 ? void 0 : (_props$row20 = props.row) === null || _props$row20 === void 0 ? void 0 : _props$row20.discount_amount)) + " ")])], 1) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }, {
      key: "pagination-bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['10', '25', '50', '100', '500']
          },
          on: {
            "input": function input(value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function callback($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function input(value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }], null, false, 1387519868)
  }) : _vm._e()], 1), _c('b-modal', {
    attrs: {
      "id": "modal-invoice-generate-form",
      "centered": "",
      "title": _vm.modelType === 'reviewModal' ? 'Review Invoice' : _vm.modelType === 'editModel' ? 'Edit Invoice' : 'Generate Invoice',
      "hide-footer": "",
      "size": "lg",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenGenerateModal
    }
  }, [_c('validation-observer', {
    ref: "generateInvoiceForm"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.generateInvoiceForm($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "lg": "12",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Seller",
      "label-for": "seller"
    }
  }, [_c('v-select', {
    staticClass: "mb-1 custom-font",
    attrs: {
      "options": _vm.filteredSellerOptions,
      "reduce": function reduce(item) {
        return item.id;
      },
      "label": "name",
      "placeholder": "Select Seller",
      "disabled": ""
    },
    model: {
      value: _vm.generateSellerId,
      callback: function callback($$v) {
        _vm.generateSellerId = $$v;
      },
      expression: "generateSellerId"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Payment Method",
      "label-for": "payment_method"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "payment method",
      "vid": "payment_method",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('v-select', {
          attrs: {
            "id": "payment_method",
            "options": _vm.filteredPaymentOptions,
            "reduce": function reduce(option) {
              return option.id;
            },
            "label": "name",
            "placeholder": "Choose Here"
          },
          model: {
            value: _vm.selectPaymentId,
            callback: function callback($$v) {
              _vm.selectPaymentId = $$v;
            },
            expression: "selectPaymentId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Approver",
      "label-for": "approver_id"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Approver",
      "vid": "approver_id",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('v-select', {
          attrs: {
            "id": "approver_id",
            "options": _vm.filteredApproverOptions,
            "reduce": function reduce(option) {
              return option.id;
            },
            "label": "name",
            "placeholder": "Choose Here"
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_c('UserSelect', {
                attrs: {
                  "user": data
                }
              })];
            }
          }], null, true),
          model: {
            value: _vm.selectApproverId,
            callback: function callback($$v) {
              _vm.selectApproverId = $$v;
            },
            expression: "selectApproverId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "12",
      "xs": "12"
    }
  }, [_vm.unpaidBackLink.length > 0 ? [_c('b-table', {
    staticClass: "mb-0",
    attrs: {
      "responsive": "",
      "items": _vm.unpaidBackLink,
      "fields": _vm.backLinkFields
    },
    scopedSlots: _vm._u([{
      key: "cell(backlink)",
      fn: function fn(data) {
        var _data$item, _data$item$site_list, _data$item$project, _data$item$project$da;
        return [_c('div', [_c('b', [_vm._v("Backlink:")]), _c('a', {
          attrs: {
            "href": ((_data$item = data.item) === null || _data$item === void 0 ? void 0 : (_data$item$site_list = _data$item.site_list) === null || _data$item$site_list === void 0 ? void 0 : _data$item$site_list.website) + '/' + data.item.backlink_url,
            "target": "_blank",
            "rel": "noopener noreferrer"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatWebsite(data.item.backlink_url)) + " ")])]), _c('div', [_c('b', [_vm._v("URL:")]), _c('a', {
          attrs: {
            "href": data.item.targeted_url,
            "target": "_blank",
            "rel": "noopener noreferrer"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatWebsite(data.item.targeted_url)) + " ")]), _c('div', [_c('b', [_vm._v("Project")]), _vm._v(": " + _vm._s((_data$item$project = data.item.project) === null || _data$item$project === void 0 ? void 0 : (_data$item$project$da = _data$item$project.data) === null || _data$item$project$da === void 0 ? void 0 : _data$item$project$da.title))])])];
      }
    }, {
      key: "cell(project)",
      fn: function fn(data) {
        var _data$item$project2, _data$item$project2$d;
        return [_c('b-badge', {
          attrs: {
            "variant": "light-primary"
          }
        }, [_vm._v(" " + _vm._s((_data$item$project2 = data.item.project) === null || _data$item$project2 === void 0 ? void 0 : (_data$item$project2$d = _data$item$project2.data) === null || _data$item$project2$d === void 0 ? void 0 : _data$item$project2$d.title) + " ")])];
      }
    }, {
      key: "cell(type)",
      fn: function fn(data) {
        return [_c('b-badge', {
          attrs: {
            "variant": "light-primary"
          }
        }, [_vm._v(" " + _vm._s(data.item.backlinks_type_text) + " ")])];
      }
    }, {
      key: "cell(status)",
      fn: function fn(data) {
        return [_vm.modelType === 'reviewModal' && _vm.$permissionAbility(_vm.BACK_LINK_REVIEW, _vm.permissions) ? [_c('v-select', {
          staticClass: "mb-1 custom-font",
          attrs: {
            "options": _vm.outreachStatusConstants,
            "reduce": function reduce(item) {
              return item.value;
            },
            "label": "name",
            "placeholder": "Select Status"
          },
          on: {
            "input": function input($event) {
              return _vm.backlinkStatusUpdate(data.item.id, data.item.status);
            }
          },
          model: {
            value: data.item.status,
            callback: function callback($$v) {
              _vm.$set(data.item, "status", $$v);
            },
            expression: "data.item.status"
          }
        })] : [_c('b-badge', {
          attrs: {
            "variant": _vm.getStatusVariant(data.item.status)
          }
        }, [_vm._v(" " + _vm._s(data.item.status_text) + " ")])]];
      }
    }, {
      key: "cell(price)",
      fn: function fn(data) {
        var _data$item$currency, _data$item$currency$d;
        return [_vm._v(" " + _vm._s((_data$item$currency = data.item.currency) === null || _data$item$currency === void 0 ? void 0 : (_data$item$currency$d = _data$item$currency.data) === null || _data$item$currency$d === void 0 ? void 0 : _data$item$currency$d.currency) + " " + _vm._s(data.item.cost_price) + " ")];
      }
    }, {
      key: "cell(action)",
      fn: function fn(data) {
        return [_vm.modelType !== 'reviewModal' ? _c('b-button', {
          attrs: {
            "variant": "danger",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              return _vm.unselectBacklink(data.index);
            }
          }
        }, [_vm._v("X")]) : [_c('b-badge', {
          attrs: {
            "variant": "light-danger"
          }
        }, [_vm._v(" N/A ")])]];
      }
    }], null, false, 115960556)
  })] : [_c('h6', {
    staticClass: "text-danger"
  }, [_vm._v("No Unpaid Backlink")])], _c('div', {
    staticClass: "d-flex justify-content-end mr-5 mb-1"
  }, [_c('div', [_c('h4', [_vm._v("Total Amount: " + _vm._s((_vm$unpaidBackLink$ = _vm.unpaidBackLink[0]) === null || _vm$unpaidBackLink$ === void 0 ? void 0 : (_vm$unpaidBackLink$$c = _vm$unpaidBackLink$.currency) === null || _vm$unpaidBackLink$$c === void 0 ? void 0 : (_vm$unpaidBackLink$$c2 = _vm$unpaidBackLink$$c.data) === null || _vm$unpaidBackLink$$c2 === void 0 ? void 0 : _vm$unpaidBackLink$$c2.currency) + " " + _vm._s(_vm.total_amount))]), _c('v-select', {
    staticClass: "mb-1 custom-font",
    attrs: {
      "options": _vm.discountTypeOptions,
      "reduce": function reduce(item) {
        return item.value;
      },
      "label": "name",
      "placeholder": "Fee Type"
    },
    on: {
      "input": _vm.feeTypeChange
    },
    model: {
      value: _vm.fee_type,
      callback: function callback($$v) {
        _vm.fee_type = $$v;
      },
      expression: "fee_type"
    }
  }), _vm.fee_type && _vm.fee_type === 'percentage' ? _c('b-form-group', {
    attrs: {
      "label": "",
      "label-for": "Fee"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "fee value",
      "vid": "fee_value",
      "rules": _vm.fee_type === 'percentage' ? 'required|min_value:0|max_value:100' : 'min_value:0|max_value:100'
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "fee_value",
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Fee Value"
          },
          on: {
            "input": _vm.calculateGrandTotal,
            "change": _vm.calculateGrandTotal
          },
          model: {
            value: _vm.fee_value,
            callback: function callback($$v) {
              _vm.fee_value = $$v;
            },
            expression: "fee_value"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2477590961)
  })], 1) : _vm._e(), _vm.fee_type ? _c('b-form-group', {
    attrs: {
      "label": "",
      "label-for": "Fee"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "fee amount",
      "vid": "fee_amount",
      "rules": _vm.fee_type === 'flat' ? 'required|min_value:0' : 'min_value:0'
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var _vm$unpaidBackLink$2, _vm$unpaidBackLink$2$, _vm$unpaidBackLink$2$2;
        var errors = _ref4.errors;
        return [_c('div', {
          staticClass: "input-group"
        }, [_c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text",
          attrs: {
            "id": "spamScoreAddon"
          }
        }, [_vm._v(_vm._s((_vm$unpaidBackLink$2 = _vm.unpaidBackLink[0]) === null || _vm$unpaidBackLink$2 === void 0 ? void 0 : (_vm$unpaidBackLink$2$ = _vm$unpaidBackLink$2.currency) === null || _vm$unpaidBackLink$2$ === void 0 ? void 0 : (_vm$unpaidBackLink$2$2 = _vm$unpaidBackLink$2$.data) === null || _vm$unpaidBackLink$2$2 === void 0 ? void 0 : _vm$unpaidBackLink$2$2.currency))])]), _c('b-form-input', {
          attrs: {
            "id": "discount_amount",
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Fee Amount",
            "disabled": _vm.fee_type === 'percentage'
          },
          on: {
            "input": _vm.calculateGrandTotal
          },
          model: {
            value: _vm.fee_amount,
            callback: function callback($$v) {
              _vm.fee_amount = $$v;
            },
            expression: "fee_amount"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2742119508)
  })], 1) : _vm._e(), _c('v-select', {
    staticClass: "mb-1 custom-font",
    attrs: {
      "options": _vm.discountTypeOptions,
      "reduce": function reduce(item) {
        return item.value;
      },
      "label": "name",
      "placeholder": "Discount Type"
    },
    on: {
      "input": _vm.discountTypeChange
    },
    model: {
      value: _vm.discount_type,
      callback: function callback($$v) {
        _vm.discount_type = $$v;
      },
      expression: "discount_type"
    }
  }), _vm.discount_type && _vm.discount_type === 'percentage' ? _c('b-form-group', {
    attrs: {
      "label": "",
      "label-for": "Discount"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "discount value",
      "vid": "discount_value",
      "rules": _vm.discount_type === 'percentage' ? 'required|min_value:0|max_value:100' : 'min_value:0|max_value:100'
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "discount_value",
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Discount Value"
          },
          on: {
            "input": _vm.calculateGrandTotal
          },
          model: {
            value: _vm.discount_value,
            callback: function callback($$v) {
              _vm.discount_value = $$v;
            },
            expression: "discount_value"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 448730114)
  })], 1) : _vm._e(), _vm.discount_type ? _c('b-form-group', {
    attrs: {
      "label": "",
      "label-for": "Discount"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "discount amount",
      "vid": "discount_amount",
      "rules": (_vm.discount_type === 'flat' ? 'required|' : '') + 'min_value:0|max_value:' + (parseFloat(_vm.total_amount) + (_vm.fee_amount ? parseFloat(_vm.fee_amount) : 0))
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var _vm$unpaidBackLink$3, _vm$unpaidBackLink$3$, _vm$unpaidBackLink$3$2;
        var errors = _ref6.errors;
        return [_c('div', {
          staticClass: "input-group"
        }, [_c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text",
          attrs: {
            "id": "spamScoreAddon"
          }
        }, [_vm._v(_vm._s((_vm$unpaidBackLink$3 = _vm.unpaidBackLink[0]) === null || _vm$unpaidBackLink$3 === void 0 ? void 0 : (_vm$unpaidBackLink$3$ = _vm$unpaidBackLink$3.currency) === null || _vm$unpaidBackLink$3$ === void 0 ? void 0 : (_vm$unpaidBackLink$3$2 = _vm$unpaidBackLink$3$.data) === null || _vm$unpaidBackLink$3$2 === void 0 ? void 0 : _vm$unpaidBackLink$3$2.currency))])]), _c('b-form-input', {
          attrs: {
            "id": "discount_amount",
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Discount Amount",
            "disabled": _vm.discount_type === 'percentage'
          },
          on: {
            "input": _vm.calculateGrandTotal
          },
          model: {
            value: _vm.discount_amount,
            callback: function callback($$v) {
              _vm.discount_amount = $$v;
            },
            expression: "discount_amount"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1610499215)
  })], 1) : _vm._e(), _c('h4', [_vm._v("Grand Total: " + _vm._s((_vm$unpaidBackLink$4 = _vm.unpaidBackLink[0]) === null || _vm$unpaidBackLink$4 === void 0 ? void 0 : (_vm$unpaidBackLink$4$ = _vm$unpaidBackLink$4.currency) === null || _vm$unpaidBackLink$4$ === void 0 ? void 0 : (_vm$unpaidBackLink$4$2 = _vm$unpaidBackLink$4$.data) === null || _vm$unpaidBackLink$4$2 === void 0 ? void 0 : _vm$unpaidBackLink$4$2.currency) + " " + _vm._s(_vm.grand_total))])], 1)])], 2), _vm.unSelectedUnpaidBackLink.length > 0 ? [_c('h4', {
    staticClass: "m-2"
  }, [_vm._v("Unselected")]), _c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "12",
      "xs": "12"
    }
  }, [_vm.unSelectedUnpaidBackLink.length > 0 ? [_c('b-table', {
    staticClass: "mb-0",
    attrs: {
      "responsive": "",
      "items": _vm.unSelectedUnpaidBackLink,
      "fields": _vm.backLinkFields
    },
    scopedSlots: _vm._u([{
      key: "cell(backlink)",
      fn: function fn(data) {
        var _data$item2, _data$item2$site_list, _data$item$project3, _data$item$project3$d;
        return [_c('div', [_c('b', [_vm._v("Backlink:")]), _c('a', {
          attrs: {
            "href": ((_data$item2 = data.item) === null || _data$item2 === void 0 ? void 0 : (_data$item2$site_list = _data$item2.site_list) === null || _data$item2$site_list === void 0 ? void 0 : _data$item2$site_list.website) + '/' + data.item.backlink_url,
            "target": "_blank",
            "rel": "noopener noreferrer"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatWebsite(data.item.backlink_url)) + " ")])]), _c('div', [_c('b', [_vm._v("URL:")]), _c('a', {
          attrs: {
            "href": data.item.targeted_url,
            "target": "_blank",
            "rel": "noopener noreferrer"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatWebsite(data.item.targeted_url)) + " ")]), _c('div', [_c('b', [_vm._v("Project")]), _vm._v(": " + _vm._s((_data$item$project3 = data.item.project) === null || _data$item$project3 === void 0 ? void 0 : (_data$item$project3$d = _data$item$project3.data) === null || _data$item$project3$d === void 0 ? void 0 : _data$item$project3$d.title))])])];
      }
    }, {
      key: "cell(project)",
      fn: function fn(data) {
        var _data$item$project4, _data$item$project4$d;
        return [_c('b-badge', {
          attrs: {
            "variant": "light-primary"
          }
        }, [_vm._v(" " + _vm._s((_data$item$project4 = data.item.project) === null || _data$item$project4 === void 0 ? void 0 : (_data$item$project4$d = _data$item$project4.data) === null || _data$item$project4$d === void 0 ? void 0 : _data$item$project4$d.title) + " ")])];
      }
    }, {
      key: "cell(type)",
      fn: function fn(data) {
        return [_c('b-badge', {
          attrs: {
            "variant": "light-primary"
          }
        }, [_vm._v(" " + _vm._s(data.item.backlinks_type_text) + " ")])];
      }
    }, {
      key: "cell(status)",
      fn: function fn(data) {
        return [_c('b-badge', {
          attrs: {
            "variant": _vm.getStatusVariant(data.item.status)
          }
        }, [_vm._v(" " + _vm._s(data.item.status_text) + " ")])];
      }
    }, {
      key: "cell(price)",
      fn: function fn(data) {
        var _data$item$currency2, _data$item$currency2$;
        return [_c('b-badge', {
          attrs: {
            "variant": "light-success"
          }
        }, [_vm._v(_vm._s((_data$item$currency2 = data.item.currency) === null || _data$item$currency2 === void 0 ? void 0 : (_data$item$currency2$ = _data$item$currency2.data) === null || _data$item$currency2$ === void 0 ? void 0 : _data$item$currency2$.currency) + " " + _vm._s(data.item.cost_price))])];
      }
    }, {
      key: "cell(action)",
      fn: function fn(data) {
        return [_c('b-button', {
          attrs: {
            "variant": "success",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              return _vm.selectUnselectedBacklink(data.index);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          attrs: {
            "icon": "CheckIcon",
            "title": "Select",
            "size": "16"
          }
        })], 1)];
      }
    }], null, false, 2233645878)
  })] : [_c('h6', {
    staticClass: "text-danger"
  }, [_vm._v("No Unselected Unpaid Backlink")])]], 2)] : _vm._e(), _c('b-col', {
    staticClass: "mb-10",
    attrs: {
      "md": "12",
      "lg": "12",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Payment Details",
      "label-for": "payment_details"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "Payment Details",
      "vid": "payment_details"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('div', {
          class: {
            fullscreen: _vm.isFullscreen
          }
        }, [_c('quill-editor', {
          staticClass: "custom-quill-editor",
          style: {
            width: '98% !important'
          },
          attrs: {
            "options": _vm.editorOption,
            "id": "payment_details",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Payment Details"
          },
          model: {
            value: _vm.paymentDetailsInput,
            callback: function callback($$v) {
              _vm.paymentDetailsInput = $$v;
            },
            expression: "paymentDetailsInput"
          }
        }, [_c('div', {
          attrs: {
            "slot": "toolbar",
            "id": "toolbar"
          },
          slot: "toolbar"
        }, [_c('span', {
          staticClass: "ql-formats"
        }, [_c('button', {
          staticClass: "ql-bold"
        }, [_vm._v(" Bold ")]), _c('button', {
          staticClass: "ql-italic"
        }, [_vm._v(" Italic ")]), _c('button', {
          staticClass: "ql-underline"
        }, [_vm._v(" Underline ")]), _c('button', {
          staticClass: "ql-strike"
        }, [_vm._v(" Strike ")]), _c('button', {
          staticClass: "ql-blockquote"
        }), _c('button', {
          staticClass: "ql-list",
          attrs: {
            "value": "ordered"
          }
        }), _c('button', {
          staticClass: "ql-list",
          attrs: {
            "value": "bullet"
          }
        }), _c('button', {
          staticClass: "ql-script",
          attrs: {
            "value": "sub"
          }
        }), _c('button', {
          staticClass: "ql-script",
          attrs: {
            "value": "super"
          }
        }), _c('button', {
          staticClass: "ql-indent",
          attrs: {
            "value": "-1"
          }
        }), _c('button', {
          staticClass: "ql-indent",
          attrs: {
            "value": "+1"
          }
        }), _c('button', {
          staticClass: "ql-direction",
          attrs: {
            "value": "rtl"
          }
        }), _c('button', {
          staticClass: "ql-align",
          attrs: {
            "value": ""
          }
        }), _c('button', {
          staticClass: "ql-align",
          attrs: {
            "value": "center"
          }
        }), _c('button', {
          staticClass: "ql-align",
          attrs: {
            "value": "right"
          }
        }), _c('button', {
          staticClass: "ql-align",
          attrs: {
            "value": "justify"
          }
        }), _c('select', {
          staticClass: "ql-color"
        }), _c('select', {
          staticClass: "ql-background"
        }), _c('select', {
          staticClass: "ql-size"
        }, [_c('option', {
          attrs: {
            "value": "small"
          }
        }), _c('option', {
          attrs: {
            "selected": ""
          }
        }), _c('option', {
          attrs: {
            "value": "large"
          }
        }), _c('option', {
          attrs: {
            "value": "huge"
          }
        })]), _c('select', {
          staticClass: "ql-font"
        }), _c('select', {
          staticClass: "ql-header"
        }, [_c('option', {
          attrs: {
            "value": "1"
          }
        }, [_vm._v(" Heading 1 ")]), _c('option', {
          attrs: {
            "value": "2"
          }
        }, [_vm._v(" Heading 2 ")]), _c('option', {
          attrs: {
            "value": "3"
          }
        }, [_vm._v(" Heading 3 ")]), _c('option', {
          attrs: {
            "value": "4"
          }
        }, [_vm._v(" Heading 4 ")]), _c('option', {
          attrs: {
            "value": "5"
          }
        }, [_vm._v(" Heading 5 ")]), _c('option', {
          attrs: {
            "value": "6"
          }
        }, [_vm._v(" Heading 6 ")]), _c('option', {
          attrs: {
            "selected": ""
          }
        }, [_vm._v("Normal")])]), _c('button', {
          staticClass: "ql-link"
        }), _c('button', {
          staticClass: "fullscreen-btn",
          attrs: {
            "type": "button",
            "title": _vm.isFullscreen ? 'Exit Fullscreen' : 'Fullscreen'
          },
          on: {
            "click": _vm.toggleFullscreen
          }
        }, [_vm._v(" 🔳 ")])])])])], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "lg": "12",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Terms & Conditions",
      "label-for": "remarks"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Remarks",
      "vid": "remarks"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var errors = _ref8.errors;
        return [_c('b-form-textarea', {
          attrs: {
            "id": "remarks",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Terms & Conditions",
            "rows": "3"
          },
          model: {
            value: _vm.remarksInput,
            callback: function callback($$v) {
              _vm.remarksInput = $$v;
            },
            expression: "remarksInput"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 2), _vm.isSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_vm.modelType === 'reviewModal' ? [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "disabled": !_vm.payment_active,
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v("Proceed to Payment")])] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "disabled": _vm.unpaidBackLink.length <= 0,
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(_vm._s(_vm.modelType === 'editModel' ? 'Update' : 'Generate'))])]]], 2)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }